<template>
    <div id="goods-box">
        <h3>商品管理</h3>
        <el-table :data="goodsList" :cell-style="{padding:'2px 0'}" height="634" border stripe>
            <el-table-column prop="id" label="id" align="center" width="100"></el-table-column>
            <el-table-column prop="name" label="商品名" align="center" width="180"></el-table-column>
            <el-table-column label="是否热门" width="100" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_hot === 1" style="color:red">是</span>
                    <span v-if="scope.row.is_hot === 2">否</span>
                </template>
            </el-table-column>
            <el-table-column prop="desc" label="简介" align="center" width="180"></el-table-column>
            <el-table-column label="价格" width="100" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.price / 100 }} 元 </span>
                </template>
            </el-table-column>
            <el-table-column prop="stock" label="库存" align="center" width="100"></el-table-column>
            <el-table-column label="封面图" width="100" align="center">
                <template slot-scope="scope">
                    <a :href="scope.row.show_picture">封面图</a>
                </template>
            </el-table-column>
            <el-table-column label="详情图1" width="100" align="center">
                <template slot-scope="scope">
                    <a :href="scope.row.desc_picture1">详情图1</a>
                </template>
            </el-table-column>
            <el-table-column label="详情图2" width="100" align="center">
                <template slot-scope="scope">
                    <a :href="scope.row.desc_picture2">详情图2</a>
                </template>
            </el-table-column>
            <el-table-column label="详情图3" width="100" align="center">
                <template slot-scope="scope">
                    <a :href="scope.row.desc_picture3">详情图3</a>
                </template>
            </el-table-column>
            <el-table-column label="使用状态" width="180" align="center">
                <template slot-scope="scope">
                    <el-button @click="toUpdateGoods(scope.row)" type="primary">修改</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { getForm } from '../tools/tool'

export default {
    name: "GoodsListView",
    data() {
        return {
            goodsList: [],
        }
    },
    created() {
        getForm('/getAllGoods')
            .then(data => {
                this.goodsList = data.data
                console.log('goodsList:', this.goodsList)
            })
    },
    mounted() { },
    methods: {
        toUpdateGood(item) {
            console.log("toUpdateGoods - item :", item);
        },
        toUpdateGoods(item) {
            this.$router.push({
                path: "/goods/info",
                query: { goodsId: item.id }
            })
        },
    },
}
</script>

<style lang="less" scoped>
</style>